<template>
    <div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-user animated bounceInDown show-info"></i>
                        Stock Transfer History
                    </h3>
                </div>
                <!-- <div class="col-md-6  flex-row content-end" v-if="isprint == 0">
                    <div class="text-right" style="margin-right:25px" v-if="edititem != null">
                        <button @click="back()" class="btn btn-primary"><i class="fa fa-backward"></i>
                            Back</button>
                    </div>
                    <div class="text-right" v-if="viewno == 1" style="margin-right:25px">
                        <button @click="refresh()" class="btn btn-refresh"><i class="fa fa-refresh"></i></button>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="col-md-12">
            <div class="row stock_section">
                <div class="col-md-5">
                    <div class="row">
                        <div class="col-md-5">
                            <label>Date : From </label>
                        </div>
                        <div class="col-md-7">
                            <input type="date" class="form-control form-cascade-control input-box-short"
                                v-model="fromdate">
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="row">
                        <div class="col-md-5">
                            <label>Date : To</label>
                        </div>
                        <div class="col-md-7">
                            <input type="date" class="form-control form-cascade-control input-box-short"
                                v-model="todate">
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-primary" @click="refresh()">Go</button>
                </div>

            </div>
        </div>
        <div class="col-md-12 table-responsive" style="padding:0px;">
            <div class="panel">
                <div class="panel-body table" style="overflow-x:auto;padding-left:0px">
                    <table class="table table-bordered table-hover table-striped display">
                        <thead class="table-font">
                            <th>#</th>
                            <th>Store From</th>
                            <th>Store To</th>
                            <th>Transaction Date</th>
                            <th>Products</th>
                        </thead>
                        <tbody v-for="(item, index) in transactions" :key="item.id">
                            <tr >
                                <td>{{ index + 1 }}</td>
                                <td>{{ getStore(item.storeid) }}</td>
                                <td>{{ getStore(item.storeid2) }}</td>
                                <td>{{ item.transactiondate }}</td>
                                <td>
                                    <div v-for="data in item.items" :key="data.id">
                                        <div>
                                            <span>{{ data.product.name }}</span>
                                            <br>
                                            <strong>Qty:- {{ data.quantity }}</strong>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            fromdate: new Date().toISOString().slice(0, 10),
            todate: new Date().toISOString().slice(0, 10),
            transactions: []
        }
    },
    mounted() {
        this.refresh()
    },
    computed: {
        ...mapGetters(['stores'])
    },
    methods: {
        refresh() {
            let param = { fromdate: this.fromdate, todate: this.todate }
            window.axios.post('api/transferouttransactions', param)
                .then((response) => this.processHistoryResponse(response.data))
                .catch((error) => console.log(error))
        },
        processHistoryResponse(data) {
            this.transactions = data
        },
        getStore(item) {
            let store = this.stores.find(o => o.id == item)
            return store?.name
        },
       
        
    }
}
</script>